@import 'styles/includes.scss';

.CategoryPage {
    background-color: $colorBlack800;

    flex: 1;
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        flex: none;
    }
}
